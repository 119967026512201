import React, { useState } from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import CircularProgress from "@mui/material/CircularProgress"; // Importing CircularProgress for loader
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import SoftBox from "components/SoftBox";

import { getVehicleTypes, getConcretePresets, updateOrder } from "api/apiService"; // Import the new API

const EditProjectAccordion = ({ data, orderNumber, onSave }) => {
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [concretePresets, setConcretePresets] = useState([]);
  const [editableData, setEditableData] = useState(data);
  const [loading, setLoading] = useState(false);  // Loader state
  const [successMessage, setSuccessMessage] = useState("");  // Success message state


  useEffect(() => {
    const getVehicleData = async () => {
      const vehicleTypesData = await getVehicleTypes(); // Fetch vehicle types from API
      setVehicleTypes(vehicleTypesData.results || []); // Assuming the vehicle types are in 'results'
    };

    getVehicleData();
  }, []);

  useEffect(() => {
    const fetchConcreteData = async () => {
      const concreteData = await getConcretePresets();  // Fetch concrete presets
      setConcretePresets(concreteData.results || []);  // Assuming the concrete presets are in 'results'
    };

    fetchConcreteData();
  }, []);  // Empty dependency array to run the effect only once

  const formatDateForInput = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleChangeDate = (index, dateValue) => {
    const updatedData = [...editableData];
    const date = new Date(dateValue);
    updatedData[index].date = Math.floor(date.getTime() / 1000);
    setEditableData(updatedData);
  };

  const handleVehicleTypeChange = (index, vehicleId) => {
  const updatedData = [...editableData];

  // Update the vehicle type
  updatedData[index].vehicle_id = vehicleId;

  // Find the selected vehicle's max load and set it as the maximum volume
  const selectedVehicle = vehicleTypes.find((vehicle) => vehicle.id === vehicleId);
  if (selectedVehicle) {
    updatedData[index].max_load = selectedVehicle.max_load;  // Store max load in the current item

    // If the volume is greater than max load, reset it to max load
    if (updatedData[index].display_volume > selectedVehicle.max_load) {
      updatedData[index].display_volume = selectedVehicle.max_load;
    }
  } else {
    updatedData[index].display_volume = 1; // Clear volume if no vehicle is selected
  }

  setEditableData(updatedData);  // Update the state
};

// Ensure the volume value is set correctly based on max_load on first render
useEffect(() => {
  const updatedData = [...editableData];
  updatedData.forEach((item, index) => {
    const selectedVehicle = vehicleTypes.find((vehicle) => vehicle.id === item.vehicle_id);
    if (selectedVehicle) {
      item.max_load = selectedVehicle.max_load;
      // Ensure the volume doesn't exceed the max load
      if (item.display_volume > item.max_load) {
        item.display_volume = item.max_load;
      }
    }
  });
  setEditableData(updatedData);
}, [vehicleTypes]);  // Trigger this effect when vehicleTypes are fetched or updated


  const handleChange = (index, field, value) => {
    const updatedData = [...editableData];
    updatedData[index][field] = value;
    setEditableData(updatedData);
  };

  const handleSave = async  (index) => {
    // Mapping concrete quality names to IDs
    setLoading(true); // Start loader
    const item = editableData[index]; // Get the data for the current index

    const hoseLength = item.add_hose === "checked" ? 1 : 0;

    // Map selected vehicle type name to the vehicle ID using the vehicleTypes array
    const vehicle = vehicleTypes.find(v => v.id === item.vehicle_id);
    const vehicleTypeId = vehicle ? vehicle.id : 0;  // Default to 0 if not found

    const concrete = concretePresets.find((c) => c.id === item.concrete_id);
    const concreteId = concrete ? concrete.id : 0; // Default to 0 if not found

    // Convert the date to UNIX timestamp (in seconds)
    const unixTimestamp = item.date; 

    // Prepare the data to be saved
    const savedData = {
      order_id: orderNumber, 
      deliveryId: index + 1,
      date: unixTimestamp, // Convert to readable date
      time: item.time,
      volume: parseFloat(item.display_volume),
      selectAreaLater: true,
      selectVehicleLater: true,
      vehicleType: vehicleTypeId, // Use the vehicle ID from the dropdown
      concreteIsCustom: item.concrete_type === "custom",
      customConcreteType: item.concrete_type === "custom" ? item.custom_concrete : null,
      concrete: concreteId,
      decline: item.decline === "checked",
      retardation: item.retardation === "checked",
      steelFiber: item.steel_fiber === "checked",
      accelerator: item.accelerator === "checked",
      pumpWash: item.pump_wash === "checked",
      pumploss: false, // Default value if not present
      extraVolume: item.extra_volume === "checked",
      addHose: item.add_hose === "checked",
      hoseLength: hoseLength, // Increment hoseLength if add_hose is checked
      price: 0,
      origianl_price: 0,
    };

    console.log("Saved Data:", savedData);
    // Call the onSave callback to pass the saved data
    // onSave(savedData);

    try {
      const response = await updateOrder(savedData);
      console.log("Order updated:", response);
      setLoading(false); // Stop loader

      if (response.status === 'success') {
        // Show success message in an alert
        alert("Successfully updated the order!");

        // Optionally, you can reload the page after 2 seconds to reflect changes
        setTimeout(() => {
          window.location.reload(); // Reload the page after success message
        }, 2000);
      }
    } catch (error) {
      console.error("Error updating order:", error);
      setLoading(false); // Stop loader

      // Show error message in an alert
      alert("Error updating the order.");
    }

  };


  return (
    <SoftBox mt={5}>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={100} />
        </div>
      )}
      <SoftTypography variant="h2" mb={2} px={1}>
        Delivery Details
      </SoftTypography>
      {editableData.map((item, index) => (
        <Accordion
          key={index}
          sx={{
            borderRadius: "16px !important",
            "&::before": { content: "none" },
            mb: 2,
            py: 4,
            px: 1,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <SoftTypography variant="h6">Load # {index + 1}</SoftTypography>
          </AccordionSummary>
          <AccordionDetails>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <SoftTypography variant="caption" fontWeight="bold" > Arrival</SoftTypography>
                    <TextField
                      type="date"
                      value={formatDateForInput(item.date)}
                      onChange={(e) => handleChangeDate(index, e.target.value)}
                      fullWidth
                    />
                  </TableCell>

                  <TableCell>
                    <SoftTypography variant="caption" fontWeight="bold"> Time</SoftTypography>
                    <TextField
                      type="time"
                      value={item.time}
                      onChange={(e) => handleChange(index, "time", e.target.value)}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell style={{ width: "100px" }}> {/* Adjust the width */}
                    <SoftTypography variant="caption" fontWeight="bold">
                      Volume
                    </SoftTypography>
                    <TextField
                      type="number"
                      value={item.display_volume || 1}  // Default to an empty string instead of NaN
                      onChange={(e) => {
                        // Parse the new volume as a float and ensure it's valid
                        const newVolume = parseFloat(e.target.value);
                        
                        // Only update the volume if it's within the allowable range
                        if (newVolume > 0 && newVolume <= item.max_load) {
                          handleChange(index, "display_volume", newVolume);
                        } else {
                          // Optionally, you can provide feedback like resetting to max load or showing an error
                          handleChange(index, "display_volume", item.max_load);
                        }
                      }}
                      style={{ width: "80px" }} 
                      inputProps={{
                        min: 1,  // Prevent negative numbers
                        max: item.max_load,  // Prevent exceeding max load
                      }}
                    />


                  </TableCell>
                  <TableCell>
                    <SoftTypography variant="caption" fontWeight="bold">
                      Vehicle Type
                    </SoftTypography>
                    <Select
                      value={item.vehicle_id || ''} // Default to empty string if vehicle_id is undefined
                      onChange={(e) => handleVehicleTypeChange(index, e.target.value)}
                      fullWidth
                    >
                      {vehicleTypes.map((vehicle) => (
                        <MenuItem key={vehicle.id} value={vehicle.id}>
                          {vehicle.name}
                        </MenuItem>
                      ))}
                    </Select>

                  </TableCell>
                  <TableCell>
                    <SoftTypography variant="caption" fontWeight="bold"> Concrete Quality</SoftTypography>
                    <Select
                      value={item.concrete_id}
                      onChange={(e) => handleChange(index, "concrete_id", e.target.value)}
                      fullWidth
                    >
                      {concretePresets.map((concrete) => (
                        <MenuItem key={concrete.id} value={concrete.id}>
                          {concrete.full_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <SoftTypography variant="caption" fontWeight="bold"> Total Price</SoftTypography>
                    <TextField
                      value={item.total_price}
                      onChange={(e) => handleChange(index, "total_price", e.target.value)}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={6}>
                    <SoftTypography fontSize=".8rem" variant="subtitle" color="secondary" sx={{ mt: 2, mb: 1 }}>
                      Modeling Options
                    </SoftTypography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={item.decline === "checked"}
                              onChange={(e) => handleChange(index, "decline", e.target.checked ? "checked" : "")}
                            />
                          }
                          label="Cast with drop"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={item.steel_fiber === "checked"}
                              onChange={(e) => handleChange(index, "steel_fiber", e.target.checked ? "checked" : "")}
                            />
                          }
                          label="Add steel fiber"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={item.retardation === "checked"}
                              onChange={(e) => handleChange(index, "retardation", e.target.checked ? "checked" : "")}
                            />
                          }
                          label="Delay"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={item.accelerator === "checked"}
                              onChange={(e) => handleChange(index, "accelerator", e.target.checked ? "checked" : "")}
                            />
                          }
                          label="Accelerator"
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={6}>
                    <SoftTypography fontSize=".8rem" variant="subtitle" color="secondary" sx={{ mt: 2, mb: 1 }}>
                      If Pump Mixer
                    </SoftTypography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={item.pump_wash === "checked"}
                              onChange={(e) => handleChange(index, "pump_wash", e.target.checked ? "checked" : "")}
                            />
                          }
                          label="Add Pump waste (0.5m³)"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={item.extra_volume === "checked"}
                              onChange={(e) => handleChange(index, "extra_volume", e.target.checked ? "checked" : "")}
                            />
                          }
                          label="Wash on mixer"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={item.add_hose === "checked"}
                              onChange={(e) => handleChange(index, "add_hose", e.target.checked ? "checked" : "")}
                            />
                          }
                          label="Snakes"
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

          
            <SoftBox mt={2}>
              <SoftButton variant="gradient" color="info" onClick={() => handleSave(index)} sx={{ mt: 2 }}>
                Save
              </SoftButton>
            </SoftBox>
          </AccordionDetails>
        </Accordion>
      ))}

      <SoftButton variant="gradient" color="info" onClick={() => setEditableData([...editableData, {}])} sx={{ mt: 2 }}>
        Add Delivery
      </SoftButton>
    </SoftBox>
  );
};

EditProjectAccordion.propTypes = {
  data: PropTypes.array.isRequired,
  orderNumber: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EditProjectAccordion;
